import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/react-bootstrap/esm/Button.js");
;
import(/* webpackMode: "eager" */ "/app/public/media/mkg-website/branches/Machinebouw_product.webp");
;
import(/* webpackMode: "eager" */ "/app/public/media/mkg-website/branches/Oppervlaktebehandeling_product.webp");
;
import(/* webpackMode: "eager" */ "/app/public/media/mkg-website/branches/part_0.webp");
;
import(/* webpackMode: "eager" */ "/app/public/media/mkg-website/branches/part_2.webp");
;
import(/* webpackMode: "eager" */ "/app/public/media/mkg-website/branches/plaatwerk_product.webp");
;
import(/* webpackMode: "eager" */ "/app/src/components/RightMouseClickMenu/RightMouseClickMenu.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["UseSessionClient"] */ "/app/src/hooks/useSessionClient.jsx");
;
import(/* webpackMode: "eager" */ "/app/src/partials/MkgEu/BranchesNavMenu/BranchesNavMenu.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/partials/MkgEu/Formulier/Formulier.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/partials/MkgEu/Klantenkaart-mkghome/KlantenkaartHome.jsx");
;
import(/* webpackMode: "eager" */ "/app/src/partials/MkgEu/NieuwsOverzicht/NieuwsOverzicht.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/partials/MkgOplossingen/mkgOplossingen.jsx");
